import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Component } from "react";
import { PnmacNavPanel, PnmacLoanSwitcher, PnmacText, PnmacCard, PnmacCardContent,PnmacContextConsumer, AppActions, AppEvents, commonStyle as styles, isSubServicingLoan, isTaxOptionsLoan, checkTaxOptionFeatureToggle } from "pnmac-universal-components";
import { phazOne, getActiveFeatures } from '../utils.js'
import { Image, View } from 'react-native'
import FadeLoader from "react-spinners/FadeLoader";

const { TOGGLE_DRAWER, SELECT_LOAN } = AppActions
const { TrackerHitTypeLID_ENUM, TrackerActionLID_ENUM, TrackerCategoryLID_ENUM, UserLanguage_ENUM } = AppEvents

const DefaultComponent = (props) => {
    return (
        <PnmacCardContent>
            <View style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", minHeight: 10 }}>
                <FadeLoader
                    size={100}
                    color={"#003187"}
                    loading={true}
                />
            </View>
        </PnmacCardContent>
    )
}

class NavBar extends Component {
    state = {
        loanId: 0
    }
    render() {
        let { translations, menuItems, location, matches, layout, dispatch } = this.props;
        let status = layout.selectedLoanAvailability?.status
        let hasPmi = (layout.currentLoan?.mortgageType == 1 || layout.currentLoan?.mortgageType == 6 || layout.currentLoan?.mortgageType == 9);
        let makeApPayment = (!phazOne(status) && !layout.modPortalVisible)
        //let wisconsinoption = layout.currentLoan?.propertyRegion == "WI" && makeApPayment && layout.currentLoan?.escrowTax?.find(s => s.taxEscrowedFlag == true)
        let wisconsinoption =  isTaxOptionsLoan(layout) //SVT-59709
        let isModPortalVisible = layout.modPortalVisible

        let hasEscrowShortageAnalysis = getActiveFeatures(layout?.features, `View_Escrow_Analysis_Payment_Change`, layout?.whitelisted) && (layout.isEscrowAnalysisEnabled)
        let isProductAndFeaturesEnabled = getActiveFeatures(layout?.features, `View_Products_And_Offers_web`, layout?.whitelisted)
        let isRefinanceOrBuyAHomeEnabled = getActiveFeatures(layout?.features, `View_Refinance_Or_Buy_Home_Web`, layout?.whitelisted)
        let hasTaxOptionsEnabled = checkTaxOptionFeatureToggle(layout)

        let listItems = [
            {
                name: isSubServicingLoan(layout) ? translations.SubServicingMyHome : translations.MyPennyHomeLink,
                canExpand: false,
                action: "HomePage",
                route: "",
                internal: true,
            },
            isProductAndFeaturesEnabled && {
                name: translations.ProductsAndOffers,
                canExpand: false,
                action: "LoanSummary",
                route: "Offers",
                internal: true,
            },            
            !layout.pennyWise && !isSubServicingLoan(layout) && isRefinanceOrBuyAHomeEnabled &&{ 
                name: translations.refinanceOrBuyAHome,
                canExpand: true,
                subList: [
                    {
                        name: translations.ApplyOnline,
                        canExpand: false,
                        action: () => window.open(`${layout.pennyHomeUrl}api/main/redirect?goingto=BlendUrl&&ids_token=${layout.token}&&user=${layout.userProfile?.user_id}`, "_blank"),
                        internal: false,
                    },
                    {
                        name: translations.MortgageRates,
                        canExpand: false,
                        action: () => window.open("https://www.pennymac.com/rates", "_blank"),
                        internal: false,
                    },
                    {
                        name: translations.HomeEquityLoans,
                        canExpand: false,
                        action: () => window.open("https://www.pennymac.com/mortgage-rates/equity", "_blank"),
                        internal: false,
                    },
                ],
            },
            {
                name: translations.loanInformation,
                canExpand: false,
                action: "LoanSummary",
                route: `Loan`,
                internal: true,
                disabled: !layout.pennyWise
            },
            layout.currentLoan?.helocFlag && {
                name: translations.MakeADrawPageTitle,
                canExpand: true,
                subList: [
                    {
                        name: translations.MakeADrawPageTitle,
                        canExpand: false,
                        action: "MakeADraw",
                        route: `MakeADraw`,
                        internal: true
                    },
                    {
                        name: translations.PendingDrawPageTitle,
                        canExpand: false,
                        action: "ViewPendingDraws",
                        route: `MakeADraw/ViewPendingDraws`,
                        internal: true,
                    }
                ],
            },
            {
                name: translations.makeAPayment,
                canExpand: true,
                subList: [
                    {
                        name: translations.oneTimePayment,
                        canExpand: false,
                        action: "Payment",
                        route: `Payment`,
                        internal: true,
                        disabled: !layout.pennyWise
                    },
                    {
                        name: translations.automaticPayments,
                        canExpand: false,
                        action: "Ach",
                        route: `Payment/Ach`,
                        internal: true,
                        disabled: !layout.pennyWise
                    },
                    {
                        name: translations.paymentViewPending,
                        canExpand: false,
                        action: "Pending",
                        route: `Payment/Pending`,
                        internal: true,
                        disabled: !layout.pennyWise
                    },
                ],
            },
            {
                name: translations.recentActivity,
                canExpand: false,
                action: "LoanActivity",
                route: `Loan/Activity`,
                internal: true,
            },
            {
                name: translations.statementsDocuments,
                canExpand: false,
                action: "StatementsAndDocuments",
                route: `StatementsAndDocuments`,
                internal: true,
            },
            {
                name: ((hasPmi || wisconsinoption || hasEscrowShortageAnalysis) ? translations.EscrowCenter : translations.escrow),
                canExpand: ((hasPmi || wisconsinoption || hasEscrowShortageAnalysis) || false),
                action: "Escrow",
                route: `Loan/Escrow`,
                internal: true,
                subList: [
                    {
                        name: translations.escrow,
                        canExpand: false,
                        action: "Escrow",
                        route: `Loan/Escrow`,
                        internal: true,
                    },
                    hasEscrowShortageAnalysis && {
                        name: translations.EscrowAnalysisPaymentChange,
                        canExpand: false,
                        action: "Escrow_Analysis_Payment_Change",
                        route: "Loan/EscrowAnalysisPaymentChange",
                        internal: true,
                    },
                    wisconsinoption && hasTaxOptionsEnabled && {
                        name: translations.TaxOptionsTitle,
                        canExpand: false,
                        action: "TaxOptions",
                        route: `Loan/TaxOptions`,
                        internal: true,
                    },
                    (hasPmi && layout.currentLoan?.mortgageInsuranceMonthlyPaymentAmount > 0) && {
                        name: translations.MortgageInsurance,
                        canExpand: false,
                        action: "MortgageInsurance",
                        route: `Loan/MortgageInsurance`,
                        internal: true,
                    }

                ].filter(s => s)
            },
            {
                name: translations.PayoffDemands,
                canExpand: false,
                action: "PayoffDemand",
                route: `Payoff`,
                internal: true,
            },
            {
                name: translations.amoritizationCalculator,
                canExpand: false,
                action: "AmortizationCalculator",
                route: `AmortizationCalculator`,
                internal: true,
            },
            {
                name: translations.messageCenter,
                canExpand: false,
                action: "MessageCenter",
                route: `MessageCenter`,
                internal: true,
            },
            {
                name: translations.faq,
                canExpand: false,
                action: "FAQ",
                route: `Home/FAQ`,
                internal: true,

            },
        ];
        if (isModPortalVisible) {
            listItems.splice(3, 0, {
                name: translations.ModificationCenter,
                canExpand: true,
                subList: [
                    {
                        name: translations.ModificationStatus,
                        route: `Mod`,
                        canExpand: false,
                        action: "Mod",
                        internal: true,
                    },
                    {
                        name: translations.ModificationForms,
                        route: `Mod/Forms`,
                        canExpand: false,
                        action: "ModForms",
                        internal: true,
                    }
                ],
            })
        }

        let loanArray = layout?.loanArray.sort((a, b) => new Date(b.loanAddedDate) - new Date(a.loanAddedDate)) || []

        listItems = listItems.filter(s => s)
        if (loanArray.length == 0) {
            loanArray = [{ loanId: 0, propertyAddressLine1: "" }]
        }

        if (matches.small || matches.medium) {
            return (
                <div
                    style={{ display: layout.drawerOpen ? "unset" : "none" }}
                >
                    {getActiveFeatures(layout.features, `theme_switcher`, layout.whitelisted) && <View style={{ paddingLeft: 20, paddingTop: 20, paddingRight: 20, borderBottomColor: 'grey', borderBottomWidth: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                        <PnmacContextConsumer title="Theme Switcher" {...this.props} />
                    </View>}

                    {loanArray?.length > 1 ? <div
                        style={{
                            width: '100%',
                            display: "flex",
                            flexDirection: "row",
                            alightItems: 'center',
                            justifyContent: "center",
                            shadowOffset: {width: 2, height: 4},
                            shadowColor: "#262626",
                            shadowOpacity: 0.2,
                            shadowRadius: 5,
                            elevation: 1,
                        }}
                    >
                        <PnmacLoanSwitcher
                            isMobile={true}
                            loans={loanArray.map(s => {
                                let title = (parseInt(s.lienPriorityType) === 2)? 'Closed End Second' : (s.helocFlag ? `- HELOC - Loan` : "Mortgage Loan")
                                return { loanId: parseInt(s.loanId), propertyStreet: s.propertyAddressLine1, displayTitle: `${title} ${parseInt(s.loanId)}`,lienPriorityType:s.lienPriorityType }
                            })}
                            selectedValue={layout.selectedLoanNumber}
                            contentStyle={{ marginTop: 25 }}
                            style={{ position: "relative", top: "unset", left: "unset", width: '100%' }}
                            onValueChange={(itemValue, itemIndex) => {
                                if (itemValue != layout.selectedLoanNumber) {
                                    this.props.dispatch({ type: SELECT_LOAN, loanId: itemValue })
                                    this.props.refreshSingleLoanData(itemValue)
                                    this.props.refreshResourcesAfterLoanSwitching()

                                }
                            }}
                        />
                    </div> : <div style={{ width: '85%', margin: "auto", marginBottom: 20, marginTop: 20 }}>

                        <PnmacCard
                            nativeID="cdLoanDisplayTile"

                                style={{
                                    minHeight: 65,
                                    shadowOffset: { width: 2, height: 4 },
                                    shadowColor: "#262626",
                                    shadowOpacity: 0.2,
                                    shadowRadius: 5,
                                    elevation: 1,
                                }}
                        >
                            {parseInt(layout.selectedLoanNumber) > 0 ? <>
                                <PnmacText style={{ padding: 5, paddingLeft: 20, fontSize: 14, fontWeight: "bold" }}>LOAN - {layout.selectedLoanNumber} {layout.currentLoan?.helocFlag ? " ( HELOC )" : null}</PnmacText>
                                <PnmacText style={{ padding: 5, paddingLeft: 20, fontSize: 16 }}>{layout?.currentLoan?.propertyAddressLine1}</PnmacText>
                            </> : <DefaultComponent />}
                        </PnmacCard>

                    </div>}
                    <PnmacNavPanel
                        accessibilityRole="menubar"
                        aria-role="menu"
                        selected={location?.pathname?.substring(1)}
                        toggleIcon={{ imageUp: <Image alt="Collapsed category carrot" style={{ marginTop: "7px", width: 12, height: 8 }} source={require("../assets/downarrow.png")} />, imageDown: <Image aria-label="expand" alt="Expanded category carrot" style={{ transform: "rotate(180deg)", marginTop: "7px", width: 12, height: 8 }} source={require("../assets/downarrow.png")} /> }}
                        selectedContainerStyles={{ borderLeftColor: "#003087", borderLeftWidth: 3, backgroundColor: "#efefef", display: "flex" }}
                        selectedStyles={{ color: "#000" }}
                        onListItemClick={(d) => {
                            if (!this.props.layout.loading) {
                                if (typeof d.action == "function") {
                                    d.action()
                                    this.props.trackerDispatcher(UserLanguage_ENUM.English, TrackerCategoryLID_ENUM.NavBar, TrackerHitTypeLID_ENUM.Event, TrackerActionLID_ENUM.Click, d.name + ` Page Viewed`, -1)
                                } else {
                                    if (d.internal && getActiveFeatures(this.props.layout.features, `View_${d.action}`, this.props.layout.whitelisted)) {
                                        this.props.history.push(`/${d.route}`);
                                        let message = d.action + ` Page Viewed`;
                                        if(d.action === 'Escrow_Analysis_Payment_Change'){
                                            message = "Escrow Analysis Payment Change Viewed";
                                        }else if(d.action === "TaxOptions"){
                                            message = "Customer Viewed Tax Option Page"
                                        }
                                        this.props.trackerDispatcher(UserLanguage_ENUM.English, TrackerCategoryLID_ENUM.NavBar, TrackerHitTypeLID_ENUM.Event, TrackerActionLID_ENUM.Click, message, 0)
                                        if (this.props.layout.drawerOpen) {
                                            this.props.dispatch({ type: TOGGLE_DRAWER, toggle: false });
                                        }
                                    } else {
                                        this.props.clearListCookies({
                                            nav: `${layout.baseRoute}${d.route}`, loading: true, logout: false, tab: false
                                        })
                                    }
                                }
                            }
                        }}
                        containerStyles={styles.navPanel.containerStyles}
                        listItemStyles={styles.navPanel.listItemStyles}
                        subListItemStyles={styles.navPanel.subListItemStyles}
                        textItemContainerStyles={styles.textItemContainerStyles}
                        textItemStyles={styles.navPanel.textItemStyles}
                        listItems={menuItems ? menuItems : listItems}
                    />
                </div>
            );
        }
        return (

            <div>

                <PnmacCard
                    nativeID="cdLoanSwitchTile"
                    style={{ height: '100vh', marginRight: 10 }}
                >
                    {getActiveFeatures(layout.features, `theme_switcher`, layout.whitelisted) && <View style={{ paddingLeft: 20, paddingTop: 20, paddingRight: 20, borderBottomColor: 'grey', borderBottomWidth: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                        <PnmacContextConsumer title="Theme Switcher" {...this.props} />
                    </View>}
                    {loanArray?.length > 1 ?
                        <div
                            style={{
                                width: '85%',
                                marginRight: "auto",
                                marginLeft: "auto",
                                marginBottom: 20,
                                shadowOffset: {width: 2, height: 4},
                                shadowColor: "#262626",
                                shadowOpacity: 0.2,
                                shadowRadius: 5,
                                elevation: 1,
                            }}
                        >
                            <PnmacLoanSwitcher
                                underLineColor={null}
                                upIcon={<img alt="Expanded category carrot" style={{ transform: "rotate(180deg)", marginTop: "7px" }} src={require("../assets/downarrow.png")} />}
                                downIcon={<img alt="Collapsed category carrot" style={{ marginTop: "7px" }} src={require("../assets/downarrow.png")} />}
                                loanStyles={{}}
                                loans={loanArray.map(s => {
                                    let title = (parseInt(s.lienPriorityType) === 2)? 'Closed End Second' : (s.helocFlag ? `- HELOC - Loan` : "Mortgage Loan")
                                    return { loanId: parseInt(s.loanId), propertyStreet: s.propertyAddressLine1, displayTitle: `${title} ${parseInt(s.loanId)}`, lienPriorityType:s.lienPriorityType }
                                })}
                                selectedValue={layout.selectedLoanNumber}
                                contentStyle={{ marginTop: 25 }}
                                style={{ position: "relative", top: "unset", left: "unset", width: '100%' }}
                                onValueChange={(itemValue, itemIndex) => {
                                    this.props.trackerDispatcher(UserLanguage_ENUM.English, TrackerCategoryLID_ENUM.NavBar, TrackerHitTypeLID_ENUM.Event, TrackerActionLID_ENUM.Click, "Loan Selection is changed", 0)
                                    if (itemValue != layout.selectedLoanNumber) {
                                        this.props.dispatch({ type: SELECT_LOAN, loanId: itemValue })
                                        this.props.refreshSingleLoanData(itemValue)
                                        this.props.refreshResourcesAfterLoanSwitching()
                                    }
                                }}
                            />
                        </div>
                        : <div style={{ width: '85%', marginLeft: "auto", marginBottom: 20, marginTop: 20, marginRight: "auto" }}>

                            <PnmacCard
                                nativeID="cdLaonDisplay"
                                style={{ minHeight: 65,
                                    shadowOffset: { width: 2, height: 4 },
                                    shadowColor: "#262626",
                                    shadowOpacity: 0.2,
                                    shadowRadius: 5,
                                    elevation: 1,}}
                            >
                                {parseInt(layout.selectedLoanNumber) > 0 ? <>
                                    <PnmacText style={{ padding: 5, paddingLeft: 20, fontSize: 14, fontWeight: "bold", fontFamily:"HalenoirCompactText-Regular" }}>LOAN - {layout.selectedLoanNumber} {layout.currentLoan?.helocFlag ? " ( HELOC )" : null}</PnmacText>
                                    <PnmacText style={{ padding: 5, paddingLeft: 20, fontSize: 16,fontFamily:"HalenoirCompactText-Regular" }}>{layout?.currentLoan?.propertyAddressLine1}</PnmacText>
                                </> : <DefaultComponent />}
                            </PnmacCard>

                        </div>}
                    <PnmacNavPanel
                        selected={location?.pathname?.substring(1)}
                        selectedContainerStyles={{ borderLeftColor: "#003087", borderLeftWidth: 3, display: "flex" }}
                        selectedStyles={{ color: "#003484" }}
                        toggleIcon={{ imageUp: <img aria-label="collapse" alt="Collapsed category carrot" style={{ width:20,height:20}} src={require("../assets/expand-icon.png")} />, imageDown: <img aria-label="expand" alt="Expanded category carrot" style={{ transform: "rotate(180deg)", width:20,height:20}} src={require("../assets/expand-icon.png")} /> }}
                        onListItemClick={(d) => {
                            if (!this.props.layout.loading) {
                                if (typeof d.action == "function") {
                                    d.action()
                                    this.props.trackerDispatcher(UserLanguage_ENUM.English, TrackerCategoryLID_ENUM.NavBar, TrackerHitTypeLID_ENUM.Event, TrackerActionLID_ENUM.Click, d.name + ` Page Viewed`, -1);
                                } else {
                                    if (d.internal && getActiveFeatures(this.props.layout.features, `View_${d.action}`, this.props.layout.whitelisted)) {
                                        this.props.history.push(`/${d.route}`);
                                        let message = d.action + ` Page Viewed`;
                                        if(d.action === 'Escrow_Analysis_Payment_Change'){
                                            message = "Escrow Analysis Payment Change Viewed";
                                        }else if(d.action === "TaxOptions"){
                                            message = "Customer Viewed Tax Option Page"
                                        }
                                        this.props.trackerDispatcher(UserLanguage_ENUM.English, TrackerCategoryLID_ENUM.NavBar, TrackerHitTypeLID_ENUM.Event, TrackerActionLID_ENUM.Click, message, 0)
                                        if (this.props.layout.drawerOpen) {
                                            this.props.dispatch({ type: TOGGLE_DRAWER, toggle: false });
                                        }

                                    } else {
                                        this.props.clearListCookies({
                                            nav: `${layout.baseRoute}${d.route}`, loading: true, logout: false, tab: false
                                        })
                                    }
                                }
                            }
                        }}
                        containerStyles={styles.navPanel.containerStyles}
                        listItemStyles={styles.navPanel.listItemStyles}
                        subListItemStyles={styles.navPanel.subListItemStyles}
                        textItemStyles={styles.navPanel.textItemStyles}
                        listItems={menuItems ? menuItems : listItems}
                    />

                </PnmacCard>
            </div>);
    }
}

export default NavBar
